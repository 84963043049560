// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#services {
    padding: 20px;
    background-color: #f9f9f9;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.service-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    width: 250px;
    text-align: center;
    transition: transform 0.2s;
}

.service-card:hover {
    transform: scale(1.05);
}

.service-card img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}
@media (min-width: 768px) {
    .industry-list {
        flex-direction: row; /* Switch to row layout on larger screens */
        justify-content: space-around;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Services.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI;QACI,mBAAmB,EAAE,2CAA2C;QAChE,6BAA6B;IACjC;AACJ","sourcesContent":["#services {\n    padding: 20px;\n    background-color: #f9f9f9;\n}\n\nh2 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.services-list {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n}\n\n.service-card {\n    background: white;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    margin: 10px;\n    padding: 15px;\n    width: 250px;\n    text-align: center;\n    transition: transform 0.2s;\n}\n\n.service-card:hover {\n    transform: scale(1.05);\n}\n\n.service-card img {\n    max-width: 100%;\n    height: auto;\n    border-radius: 5px;\n}\n@media (min-width: 768px) {\n    .industry-list {\n        flex-direction: row; /* Switch to row layout on larger screens */\n        justify-content: space-around;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
