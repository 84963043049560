import React, { useEffect, useState } from 'react';
import './Training.css'; // Add your styles here
import apiUrl from '../config/apiConfig';

const Training = () => {
    const [trainingPrograms, setTrainingPrograms] = useState([]);
    const [heading, setHeading] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            //const response = await fetch(apiUrl);
            const response = await fetch('/data.json');
            const data = await response.json();
            setTrainingPrograms(data.training.programs);
            setHeading(data.training.heading);
        };

        fetchData();
    }, []);

    const [expanded, setExpanded] = useState({}); // State to track expanded items

    const toggleExpand = (index, type) => {
        setExpanded((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                [type]: !prev[index]?.[type],
            },
        }));
    };

    if (trainingPrograms.length === 0) return <div>Loading...</div>; // Handle loading state

    return (
        <section className="training-section">
            <h1>{heading}</h1>
            <div className="training-list">
                {trainingPrograms.map((program, index) => (
                    <div key={index} className="training-item">
                        <img 
                            src={`${process.env.PUBLIC_URL}/${program.image}`} 
                            alt={program.title} 
                            className="training-image" 
                        />
                        <h2>{program.title}</h2>

                        <div>
                            <strong>Description:</strong>
                            <p>
                                {expanded[index]?.description 
                                    ? program.description 
                                    : `${program.description.substring(0, 200)}...`}
                            </p>
                            <button onClick={() => toggleExpand(index, 'description')}>
                                {expanded[index]?.description ? 'Show Less' : 'Show More'}
                            </button>
                        </div>

                        <div>
                            <strong>Prerequisites:</strong>
                            <p>
                                {expanded[index]?.prerequisites
                                    ? program.prerequisites
                                    : `${program.prerequisites.substring(0, 200)}...`}
                            </p>
                            <button onClick={() => toggleExpand(index, 'prerequisites')}>
                                {expanded[index]?.prerequisites ? 'Show Less' : 'Show More'}
                            </button>
                        </div>

                        <p><strong>Duration:</strong> {program.duration}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Training;
