// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Industries.css */
.industry-container {
    padding: 20px;
    text-align: center;
}

.industry-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column; /* Change to row for horizontal layout */
    gap: 20px; /* Space between items */
}

.industry-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.industry-image {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
    margin-bottom: 10px; /* Space between image and text */
}

.industry-details {
    font-size: 0.9em; /* Slightly smaller font size for details */
    color: #555; /* Gray color for better readability */
    margin-top: 10px; /* Space above details */
}

@media (min-width: 768px) {
    .industry-list {
        flex-direction: row; /* Switch to row layout on larger screens */
        justify-content: space-around;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Industries.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,aAAa;IACb,sBAAsB,EAAE,wCAAwC;IAChE,SAAS,EAAE,wBAAwB;AACvC;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,wCAAwC;AAC5C;;AAEA;IACI,WAAW,EAAE,8BAA8B;IAC3C,YAAY,EAAE,0BAA0B;IACxC,kBAAkB;IAClB,mBAAmB,EAAE,iCAAiC;AAC1D;;AAEA;IACI,gBAAgB,EAAE,2CAA2C;IAC7D,WAAW,EAAE,sCAAsC;IACnD,gBAAgB,EAAE,wBAAwB;AAC9C;;AAEA;IACI;QACI,mBAAmB,EAAE,2CAA2C;QAChE,6BAA6B;IACjC;AACJ","sourcesContent":["/* src/components/Industries.css */\n.industry-container {\n    padding: 20px;\n    text-align: center;\n}\n\n.industry-list {\n    list-style-type: none;\n    padding: 0;\n    display: flex;\n    flex-direction: column; /* Change to row for horizontal layout */\n    gap: 20px; /* Space between items */\n}\n\n.industry-item {\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    padding: 20px;\n    background-color: #f9f9f9;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.industry-image {\n    width: 100%; /* Make the image responsive */\n    height: auto; /* Maintain aspect ratio */\n    border-radius: 8px;\n    margin-bottom: 10px; /* Space between image and text */\n}\n\n.industry-details {\n    font-size: 0.9em; /* Slightly smaller font size for details */\n    color: #555; /* Gray color for better readability */\n    margin-top: 10px; /* Space above details */\n}\n\n@media (min-width: 768px) {\n    .industry-list {\n        flex-direction: row; /* Switch to row layout on larger screens */\n        justify-content: space-around;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
